import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";
// import { PivotPhoneImg } from "../../Utils/ImageImportFile";

export const TechOutcomeContainer = ({ data, image, points, caseStudy }) => {
	const screen = useWindowMediaQuery();
	return (
		<TechOutcomeStyled caseStudy={caseStudy} screen={screen}>
			<Row>
				<Col
					lg={7}
					md={6}
					className="text-end d-flex align-items-center order-md-0 order-1">
					<img src={image} className="img-fluid" alt="" />
				</Col>
				<Col
					lg={5}
					md={6}
					className="d-flex flex-column justify-content-center order-md-1 order-0">
					<h4>Service</h4>
					<h2>Tech Outcome</h2>
					<p>{data}</p>
					<ul>
						{points.map((point, index) => (
							<li key={index}>{point}</li>
						))}
					</ul>
				</Col>
			</Row>
		</TechOutcomeStyled>
	);
};

const TechOutcomeStyled = styled.div`
	margin-top: ${({ screen }) => (screen.md ? "140px" : "70px")};
	margin-bottom: ${({ screen }) => (screen.md ? "100px" : "20px")};

	h4 {
		font-weight: normal;
		font-size: ${({ screen }) => (screen.xl ? "24px" : "20px")};
		line-height: ${({ screen }) => (screen.xl ? "32px" : "26px")};
		color: var(--bs-primary);
	}

	h2 {
		margin-bottom: ${({ screen }) => (screen.md ? "35px" : "16px")};
		font-weight: bold;
		font-size: ${({ screen }) => (screen.xl ? "50px" : "24px")};
		line-height: ${({ screen }) => (screen.xl ? "64px" : "42px")};
		color: var(--bs-primary);
	}

	p {
		font-weight: normal;
		font-size: ${({ screen }) => (screen.xl ? "18px" : "16px")};
		line-height: ${({ screen }) => (screen.xl ? "30px" : "28px")};
		color: var(--bs-primary);
	}

	${({ caseStudy, screen }) =>
		(caseStudy !== "pivot" && caseStudy !== "float")? `
	img{
		width: 100%;
		max-width: 500px;

		${screen.md ? "margin-right: auto;" : "margin: 22px auto; "}
	}
	`
	: `img {
		width: 100%;
		max-width: 500px;
				margin-right: auto;
		
	}`}
`;
