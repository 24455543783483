const Jobs = () => {
  return (
      <iframe 
        style={{ position: 'absolute', width: '100%', height: '100%', border: 'none', padding: 0, margin: 0 }}
        src="https://www.canva.com/design/DAF67RTdiFM/2QgL6ykkVKVT4_kz6EOQXQ/view?embed"
        allowFullScreen="allowfullscreen"
        allow="fullscreen">
      </iframe>
  );
}

export default Jobs;