import styled from "styled-components";
import {
	FloatLogo,
	Laptop_Mobile_Mockup_1,
	Laptop_Mobile_Mockup_2,
	Laptop_Mobile_Mockup_5,
	Laptop_Mobile_Mockup_6,
	PivotLogo,
	GlobalPassLogo2,
	OttawaLogo,
} from "../../Utils/ImageImportFile";
import {
	LeftImageCaseStudy,
	RightImageCaseStudy,
} from "../../Shared/CaseStudiesCard";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const PortfolioSection = () => {
	const screen = useWindowMediaQuery();
	return (
		<PortfolioSectionStyled screen={screen}>
			<h6>Our Projects</h6>
			<h3>Portfolio</h3>

			<LeftImageCaseStudy
				link="/case-study/pivot"
				mainImg={Laptop_Mobile_Mockup_1}
				companyIcon={PivotLogo}
				title={"Case Study #1"}
				text={
					"Pivot optimizes the management of schedules and payroll in addition to facilitating communication in the restaurant & retail industry."
				}
			/>
			<RightImageCaseStudy
				link="/case-study/float"
				className={` ${screen.md ? "" : "mt-125"}`}
				mainImg={Laptop_Mobile_Mockup_2}
				companyIcon={FloatLogo}
				title={"Case Study #2"}
				text={
					"Float is an online booking platform that empowers marinas to display their businesses on a global scale while increasing revenue, saving time and letting their customers book and pay online."
				}
			/>
			<LeftImageCaseStudy
				link="/case-study/ottawa"
				className="mt-125 img-unset"
				mainImg={Laptop_Mobile_Mockup_5}
				companyIcon={OttawaLogo}
				title={"Case Study #3"}
				text={
					"A non-profit government-proven organization that improves the health and access for people who are chronically homeless."
				}
			/>
			<RightImageCaseStudy
				link="/case-study/globalPass"
				className="mt-125"
				mainImg={Laptop_Mobile_Mockup_6}
				companyIcon={GlobalPassLogo2}
				title={"Case Study #4"}
				text={
					"Education Technology Company that helps international students apply to university and study visa"
				}
			/>
		</PortfolioSectionStyled>
	);
};

const PortfolioSectionStyled = styled.div`
	width: ${({ screen }) => (screen.xl ? "80%" : "90%")};
	margin: 0 auto;
	margin-top: ${({ screen }) => (screen.md ? "110px" : "5px")};
	max-width: var(--max-width-content);

	h6 {
		font-weight: normal;
		font-size: 24px;
		line-height: 28px;
		color: var(--bs-primary);
	}

	h3 {
		font-weight: bold;
		font-size: ${({ screen }) => (screen.xl ? "56px" : "42px")};
		line-height: ${({ screen }) => (screen.xl ? "66px" : "56px")};
		color: var(--bs-primary);
		margin-bottom: 100px;
	}

	.mt-125 {
		margin-top: 125px;
	}

	.image-col {
		img {
			margin-bottom: ${({ screen }) => (screen.md ? "0" : "20px")};
			/* max-width: 600px;
			max-height: 450px; */
		}
	}

	.img-unset {
		.content-box {
			img {
				width: unset !important;
				height: unset !important;
			}
		}
	}
`;
