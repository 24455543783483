import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import {
	Jack,
	William,
	Henry,
	Karen,
	Adam,
	Derek,
	Juan,
	Rushi
} from "../../Utils/ImageImportFile";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

const profiles = [
	{
		image: Jack,
		name: "Jack Wu",
		position: "CEO & Founder",
	},
	{
		image: Juan,
		name: "Juan Karamoy",
		position: "Partner",
	},
	{
		image: Adam,
		name: "Adam Crazh",
		position: "Partner",
	},
	{
		image: Henry,
		name: "Henry Morris",
		position: "Full-stack developer",
	},
	{
		image: William,
		name: "William Trinh",
		position: "Full-stack Developer",
	},
	{
		image: Rushi,
		name: "Rushi Patel",
		position: "Software Developer"
	},
	{
		image: Derek,
		name: "Derek Lao",
		position: "Software Developer",
	},
	{
		image: Karen,
		name: "Karen Wang",
		position: "Project Manager",
	},
];

export const MeetYourExpertsSection = () => {
	const screen = useWindowMediaQuery();
	return (
		<ExpertsWrapper screen={screen}>
			<h6>Meet Your Experts</h6>
			<h3>Team</h3>

			<ExpertsStyled screen={screen}>
				{profiles.map((person, index) => (
					<Col key={index}>
						<ExpertsCard screen={screen} person_data={person} />
					</Col>
				))}
			</ExpertsStyled>
		</ExpertsWrapper>
	);
};

const ExpertsCard = ({ person_data, screen }) => (
	<Box screen={screen}>
		<img src={person_data.image} alt="" />

		<h5>{person_data.name}</h5>
		<p>{person_data.position}</p>
	</Box>
);

const ExpertsWrapper = styled.div`
	h6 {
		font-weight: normal;
		font-size: 24px;
		line-height: 32px;
		color: var(--bs-primary);
	}

	h3 {
		font-weight: bold;
		font-size: ${({ screen }) =>
			screen.xl ? "50px" : screen.md ? "34px" : "24px"};
		line-height: ${({ screen }) =>
			screen.xl ? "64px" : screen.md ? "42px" : "32px"};
		color: var(--bs-primary);
		margin-bottom: 35px;
	}

	.col{
		flex: unset;
		width: ${({ screen }) => screen.md ? "20%" : screen.xs ? "50%" : "unset"};
	}
`;

const ExpertsStyled = styled(Row)``;

const Box = styled.div`
	margin-bottom: ${({ screen }) => (screen.md ? "50px" : "30px")};

	img {
		width: -webkit-fill-available;
		width: ${({ screen }) => (screen.md ? "255px" : "unset")};
		max-width: 100%;
		max-height: ${({ screen }) => (screen.md ? "300px" : "unset")};
		background-blend-mode: luminosity;
		filter: grayscale(1);
	}

	h5 {
		margin-top: 15px;
		margin-bottom: 5px;
		font-weight: bold;
		font-size: ${({ screen }) => (screen.xl ? "24px" : "20px")};
		line-height: 42px;
		color: var(--bs-primary);
	}

	p {
		margin: 0;
		font-weight: normal;
		font-size: 16px;
		line-height: 28px;
		color: var(--bs-primary);
	}
`;
