import HeaderLogo from "../../Assets/Header/Logo.png";
import FooterLogo from "../../Assets/Footer/Logo.png";

import HeaderLogoMobile from "../../Assets/Header/Logo _ Black.png";

// ------------------------------Landing Page--------------------------

import LaptopCompGif from "../../Assets/LandingPage/Slayt.gif";
import LandingPageCurves from "../../Assets/LandingPage/BG _ LooperGroup.svg";
import LandingPageStep1Animation from "../../Assets/LandingPage/round_animation.gif";
import LandingPageStep2Animation from "../../Assets/LandingPage/Deployment_icon.gif";
import LandingPageStep3Animation from "../../Assets/LandingPage/Maintanance_icon.gif";

import DesignDev from "../../Assets/LandingPage/design.png";
import Deployment from "../../Assets/LandingPage/deployment.png";
import Maintenance from "../../Assets/LandingPage/maintenance.png";

import DesignGifImg from "../../Assets/LandingPage/design-gif-img.png";
import DevelopmentGifImg from "../../Assets/LandingPage/dev-gif-img.png";

import PivotLogo from "../../Assets/LandingPage/pivot_logo.png";
import FloatLogo from "../../Assets/LandingPage/Float_logo.png";
import GlobalPassLogo from "../../Assets/LandingPage/globalPass_logo.png";
import DattwaLogo from "../../Assets/LandingPage/dttwa_logo.png";
import BoxiaLogo from "../../Assets/LandingPage/Boxia_logo.png";

import Laptop_Mobile_Mockup_1 from "../../Assets/LandingPage/laptop_mob_1.png";
import Laptop_Mobile_Mockup_2 from "../../Assets/LandingPage/laptop_mob_2.png";
import WhoWeAreImage from "../../Assets/LandingPage/who_we_are.png";

// ------------------------------About Page--------------------------
import QuotationMark1 from "../../Assets/AboutPage/question_mark1.gif";
import QuotationMark2 from "../../Assets/AboutPage/question_mark2.gif";
import Design from "../../Assets/AboutPage/Design-Img.gif";
import Engineering from "../../Assets/AboutPage/Engineering-Img.gif";
import Growth from "../../Assets/AboutPage/Growth.gif";
import MainImage from "../../Assets/AboutPage/mainImage.png";
import MainImageMobile from "../../Assets/AboutPage/mainImageMobile.png";

import Jack from "../../Assets/AboutPage/jack.png";
import William from "../../Assets/AboutPage/william.png";
import Henry from "../../Assets/AboutPage/henry.png";
import Karen from "../../Assets/AboutPage/karen.png";
import Adam from "../../Assets/AboutPage/adam.png";
import Derek from "../../Assets/AboutPage/derek.png";
import Juan from "../../Assets/AboutPage/juan.png";
import Rushi from "../../Assets/AboutPage/rushi.png";

import DesignSvg from "../../Assets/AboutPage/design.png";
import EnggSvg from "../../Assets/AboutPage/engg.png";
import GrowthSvg from "../../Assets/AboutPage/growth.png";

// ------------------------------Approach Page--------------------------
import Research from "../../Assets/ApproachPage/Research_icon.gif";
import Strategy from "../../Assets/ApproachPage/Strategy_icon.gif";
import Evaluation from "../../Assets/ApproachPage/Evaluation_icon.gif";
import ApproachPageCurves from "../../Assets/ApproachPage/curves.svg";

import ResearchJpeg from "../../Assets/ApproachPage/research.png";
import StrategyJpeg from "../../Assets/ApproachPage/strategy.png";
import ProductDesignJpeg from "../../Assets/ApproachPage/product-design.png";
import EngineeringJpeg from "../../Assets/ApproachPage/engineering.png";
import LaunchGrowJpeg from "../../Assets/ApproachPage/launch-grow.png";
import EvaluationJpeg from "../../Assets/ApproachPage/evaluation.png";

import GlobalPassLogo2 from "../../Assets/ApproachPage/global_pass_logo.png";

import Laptop_Mobile_Mockup_3 from "../../Assets/ApproachPage/laptop_img_3.png";
import Laptop_Mobile_Mockup_4 from "../../Assets/ApproachPage/laptop_img_4.png";
import Laptop_Mobile_Mockup_5 from "../../Assets/ApproachPage/laptop_img_5.png";
import Laptop_Mobile_Mockup_6 from "../../Assets/ApproachPage/laptop_img_6.png";

import Boxia from "../../Assets/ApproachPage/boxia.svg";
import Public_Co_Map from "../../Assets/ApproachPage/public_comap.svg";

// ------------------------------Marketing Page--------------------------
import MarketingPageCurves from "../../Assets/Marketing/curves.svg";
import MasteryDemandsFocus from "../../Assets/Marketing/Mastery Demands Focus_icon.gif";
import PaidAdvertisingIcon from "../../Assets/Marketing/Paid Advertising_icon.gif";

import MasteryDemandJpeg from "../../Assets/Marketing/market.png";
import PaidAdvertising from "../../Assets/Marketing/paid.png";

import Number_1 from "../../Assets/Marketing/1.svg";
import Number_2 from "../../Assets/Marketing/2.svg";
import Number_3 from "../../Assets/Marketing/3.svg";
import Number_4 from "../../Assets/Marketing/4.svg";

// ------------------------------Contact Page--------------------------
import ContactPageCurves from "../../Assets/ContactPage/curves.svg";

// ------------------------------Career Page--------------------------
import CareerPageCurves from "../../Assets/CareerPage/curves.svg";
import Career_Purpose_1 from "../../Assets/CareerPage/purpose_1.png";
import Career_Purpose_2 from "../../Assets/CareerPage/purpose_2.png";
import Career_Purpose_3 from "../../Assets/CareerPage/purpose_3.png";
import Star_Team from "../../Assets/CareerPage/star_team.png";

// ------------------------------Case Study Page--------------------------
import PivotMainImg from "../../Assets/CaseStudyPage/MainMockup1.png";
import PivotCompImg from "../../Assets/CaseStudyPage/pivotComp.png";
import PivotPhoneImg from "../../Assets/CaseStudyPage/pivotMobile.png";
import PivotIPadImg from "../../Assets/CaseStudyPage/pivotIPad.png";

import FloatCompImg from "../../Assets/FloatCaseStudy/FloatCompImg.png";
import FloatPhoneImg from "../../Assets/FloatCaseStudy/FloatMobileImg.png";
import FloatIPadImg from "../../Assets/FloatCaseStudy/FloatTabletImage.png";

import OttawaLogo from "../../Assets/OttawaCaseStudy/ottawaLogo.png";
import OttawaMainImage from "../../Assets/OttawaCaseStudy/ottawaHeroImg.png";
import OttawaCompImage from "../../Assets/OttawaCaseStudy/OttawaCompImg.png";
import OttawaMobileImage from "../../Assets/OttawaCaseStudy/OttawaMobileImg.png";
import OttawaIPadImage from "../../Assets/OttawaCaseStudy/OttawaIPadImg.png";

// ------------------------------Global Pass Page--------------------------
import GlobalPassMainIMg from "../../Assets/GlobalPass/globalComp.png";
import GlobalPassCompIMg from "../../Assets/GlobalPass/globalComp1.png";
import GlobalPassMobileIMg from "../../Assets/GlobalPass/globalMobile.png";
import GlobalPassIPadIMg from "../../Assets/GlobalPass/globalIpad.png";

// ------------------------------Technologies Page--------------------------
import JavaScript from "../../Assets/FloatCaseStudy/JS.png";
import AWS from "../../Assets/FloatCaseStudy/aws.png";
import Html5 from "../../Assets/FloatCaseStudy/html.png";
import Css3 from "../../Assets/FloatCaseStudy/css.png";
import Ruby from "../../Assets/FloatCaseStudy/Ruby.png";
import Rails from "../../Assets/FloatCaseStudy/Rails.png";
import SQL from "../../Assets/FloatCaseStudy/SQL.png";

//------------------------------WAAP Page / Project Site------------------------------------
import GetYouBusinessOnline from "../../Assets/WaapPage/OurValueProp/get-your-business-online.jpg"
import Example from "../../Assets/WaapPage/All/example1.jpg";

import DC_1_XPLetsPartner_Cover from "../../Assets/WaapPage/DesignCatalog/XP/LetsPartner/cover-image.png";
import DC_1_XPLetsPartner_1 from "../../Assets/WaapPage/DesignCatalog/XP/LetsPartner/img-1.png";
import DC_1_XPLetsPartner_2 from "../../Assets/WaapPage/DesignCatalog/XP/LetsPartner/img-2.png";
import DC_1_XPLetsPartner_3 from "../../Assets/WaapPage/DesignCatalog/XP/LetsPartner/img-3.png";

import DC_1_XPLandingPage_Cover from "../../Assets/WaapPage/DesignCatalog/XP/LandingPage/cover-image.jpg";
import DC_1_XPLandingPage_1 from "../../Assets/WaapPage/DesignCatalog/XP/LandingPage/scroll-image.jpg";

import DC_1_XPAboutUsPage_Cover from "../../Assets/WaapPage/DesignCatalog/XP/AboutUsPage/cover-image.png";
import DC_1_XPAboutUsPage_1 from "../../Assets/WaapPage/DesignCatalog/XP/AboutUsPage/scroll-image.png";

import DC_2_ExpLabCaseStudy_Cover from "../../Assets/WaapPage/DesignCatalog/ExpLabs/CaseStudy/cover-image.png";
import DC_2_ExpLabCaseStudy_Scroll from "../../Assets/WaapPage/DesignCatalog/ExpLabs/CaseStudy/scroll-image.png";

import DC_3_ExpLabApproach_Cover from "../../Assets/WaapPage/DesignCatalog/ExpLabs/Approach/cover-image.png";
import DC_3_ExpLabApproach_Scroll from "../../Assets/WaapPage/DesignCatalog/ExpLabs/Approach/scroll-image.png";

import Affogato_Cover from "../../Assets/WaapPage/DesignCatalog/Affogato/Affogato-Cover.png";
import Affogato_Scroll from "../../Assets/WaapPage/DesignCatalog/Affogato/Affogato-Scroll.png";

import Antic_Cover from "../../Assets/WaapPage/DesignCatalog/Antic/Antic-Cover.png";
import Antic_Scroll from "../../Assets/WaapPage/DesignCatalog/Antic/Antic-Scroll.png";

import Camp_Cover from "../../Assets/WaapPage/DesignCatalog/Camp/Camp-hub-Cover.png";
import Camp_Scroll from "../../Assets/WaapPage/DesignCatalog/Camp/Camp-hub-Scroll.png";

import Planet_Cover from "../../Assets/WaapPage/DesignCatalog/Planet/Planet-Cover.png";
import Planet_Scroll from "../../Assets/WaapPage/DesignCatalog/Planet/Planet-Scroll.png";

import Techmart_Cover from "../../Assets/WaapPage/DesignCatalog/Techmart/Techmart-Cover.png";
import Techmart_Scroll from "../../Assets/WaapPage/DesignCatalog/Techmart/Techmart-Scroll.png";

export {
	HeaderLogo,
	FooterLogo,
	HeaderLogoMobile,
	LandingPageCurves,
	LaptopCompGif,
	DesignGifImg,
	DevelopmentGifImg,
	PivotLogo,
	FloatLogo,
	GlobalPassLogo,
	DattwaLogo,
	BoxiaLogo,
	WhoWeAreImage,
	QuotationMark1,
	QuotationMark2,
	Design,
	Engineering,
	Growth,
	MainImage,
	MainImageMobile,
	Laptop_Mobile_Mockup_1,
	Laptop_Mobile_Mockup_2,
	LandingPageStep1Animation,
	LandingPageStep2Animation,
	LandingPageStep3Animation,
	Jack,
	William,
	Henry,
	Karen,
	Adam,
	Derek,
	Juan,
	Rushi,
	Research,
	Strategy,
	Evaluation,
	GlobalPassLogo2,
	ApproachPageCurves,
	Laptop_Mobile_Mockup_3,
	Laptop_Mobile_Mockup_4,
	Laptop_Mobile_Mockup_5,
	Laptop_Mobile_Mockup_6,
	Boxia,
	Public_Co_Map,
	MarketingPageCurves,
	MasteryDemandsFocus,
	PaidAdvertisingIcon,
	Number_1,
	Number_2,
	Number_3,
	Number_4,
	ContactPageCurves,
	CareerPageCurves,
	Career_Purpose_1,
	Career_Purpose_2,
	Career_Purpose_3,
	Star_Team,
	PivotCompImg,
	PivotMainImg,
	PivotPhoneImg,
	PivotIPadImg,
	FloatCompImg,
	FloatPhoneImg,
	FloatIPadImg,
	JavaScript,
	Html5,
	Css3,
	AWS,
	Ruby,
	Rails,
	SQL,
	OttawaMainImage,
	OttawaCompImage,
	OttawaMobileImage,
	OttawaIPadImage,
	OttawaLogo,
	// ---------------Gif's Svgs--------
	DesignDev,
	Deployment,
	Maintenance,
	DesignSvg,
	EnggSvg,
	GrowthSvg,
	ResearchJpeg,
	StrategyJpeg,
	ProductDesignJpeg,
	EngineeringJpeg,
	LaunchGrowJpeg,
	EvaluationJpeg,
	MasteryDemandJpeg,
	PaidAdvertising,
	GlobalPassCompIMg,
	GlobalPassMainIMg,
	GlobalPassIPadIMg,
	GlobalPassMobileIMg,
	//----------WAAP page / Project Site--------
	GetYouBusinessOnline,
	Example,
	DC_1_XPLetsPartner_Cover,
	DC_1_XPLetsPartner_1,
	DC_1_XPLetsPartner_2,
	DC_1_XPLetsPartner_3,
	DC_1_XPLandingPage_Cover,
	DC_1_XPLandingPage_1,
	DC_1_XPAboutUsPage_Cover,
	DC_1_XPAboutUsPage_1,
	DC_2_ExpLabCaseStudy_Cover,
	DC_2_ExpLabCaseStudy_Scroll,
	DC_3_ExpLabApproach_Cover,
	DC_3_ExpLabApproach_Scroll,
	Techmart_Scroll,
	Techmart_Cover,
	Planet_Scroll,
	Planet_Cover,
	Camp_Scroll,
	Camp_Cover,
	Antic_Scroll,
	Antic_Cover,
	Affogato_Scroll,
	Affogato_Cover
};
