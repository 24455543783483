import styled from "styled-components";
import { Row } from "react-bootstrap";
import {
	FloatLogo,
	Laptop_Mobile_Mockup_1,
	Laptop_Mobile_Mockup_2,
	PivotLogo,
} from "../../Utils/ImageImportFile";
import {
	LeftImageCaseStudy,
	RightImageCaseStudy,
} from "../../Shared/CaseStudiesCard";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const WhatWeDoSection = () => {
	const screen = useWindowMediaQuery();

	return (
		<WhatWeDoSectionStyled screen={screen}>
			<h3>{screen.md ? "Launching products that scale" : "Case Studies"} </h3>
			{screen.md && (
				<h6>
					We work with an array of clients, from global brands and media properties
					to goverment agencies, innovative startups and funded ventures.
				</h6>
			)}
			<LeftImageCaseStudy
				className="mb-5"
				link="/case-study/pivot"
				mainImg={Laptop_Mobile_Mockup_1}
				companyIcon={PivotLogo}
				title={"Case Study #1"}
				text={
					"Pivot optimizes the management of schedules and payroll in addition to facilitating communication in the restaurant & retail industry."
				}
			/>
			<RightImageCaseStudy
				link="/case-study/float"
				mainImg={Laptop_Mobile_Mockup_2}
				companyIcon={FloatLogo}
				title={"Case Study #2"}
				text={
					"Float is an online booking platform that empowers marinas to display their	businesses on a global scale while increasing revenue, saving time and letting their customers book and pay online."
				}
			/>{" "}
		</WhatWeDoSectionStyled>
	);
};

const WhatWeDoSectionStyled = styled(Row)`
	max-width: ${({ screen }) => (screen.xxl ? "var(--max-width-content)" : "90%")};
	margin: ${({ screen }) => (screen.md ? "0 auto" : "20px auto")};
	margin-bottom: ${({ screen }) => (screen.md ? "75px" : "100px")};

	h6 {
		font-size: 24px;
		line-height: 32px;
		font-weight: 400;
		color: var(--bs-primary);
		margin-bottom: 50px;
	}

	h3 {
		font-size: ${({ screen }) =>
			screen.xl ? "50px" : screen.md ? "42px" : "32px"};
		font-weight: bold;
		line-height: ${({ screen }) =>
			screen.xl ? "60px" : screen.md ? "56px" : "42px"};
		color: var(--bs-primary);
		margin-bottom: 10px;
	}
`;
